<template>
  <div class="total-box">
    <div class="order-item">{{ price }}</div>
    <div class="order-item">{{ $gbUtils.formatBalance(totalAmount) }}</div>
    <div class="order-item">
      <van-button class="check-item" @click="handleCheck">{{type === 1 ?  $t('otc.sell') : $t('otc.purchase')}}</van-button>
    </div>
    <OrderPriceList v-if="priceListShow"
    :price="price" :type="type"
    @close="handleCheck"
    @handleTrade="handleTrade"
    />
    <TradePop v-if="tradeShow" @close="() => {tradeShow = false}"
      :id="curId" :type="curType" :orderInfo="curInfo"
      />
  </div>
</template>

<script>
import contractOtc from '../../../contracts/contractOtcAgent'
import OrderPriceList from './OrderPriceList.vue'
import TradePop from './TradePop.vue'

export default {
  name: '',
  props: ['price', 'type'],
  components: { OrderPriceList, TradePop },
  data () {
    return {
      totalAmount: 0,
      priceListShow: false,
      tradeShow: false,
      curId: '',
      curType: '',
      curInfo: '',
      timer: null
    }
  },
  watch: {
    price (val) {
      this.init()
    }
  },
  methods: {
    init () {
      this.getPriceTotals()
    },
    async getPriceTotals () {
      const resp = await contractOtc.getPriceTotals(this.type, this.price)
      if (!resp.success) return
      this.totalAmount = resp.result || 0
    },
    // 查看
    handleCheck () {
      this.priceListShow = !this.priceListShow
    },
    // 单个买入
    handleTrade (type, id, info) {
      this.curType = type
      this.curId = id
      this.curInfo = info
      this.tradeShow = true
    },
    initTimer () {
      this.timer = setInterval(() => {
        this.init()
      }, 15000)
    }
  },
  mounted () {
    this.init()
    this.initTimer()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style scoped lang="scss">
.total-box {
  display: flex;
  align-items: center;
  .order-item {
    width: 33%;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    font-size: 14px;
    .check-item {
      height: 24px;
      background: #18191A;
      border: 1px solid #8080FF!important;
      border-radius: 12px;
      color: #8080FF;
      font-size: 14px;
    }
  }
}
</style>
